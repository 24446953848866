export const enum Environment {
  Local = 'local',
  Staging = 'staging',
  Demo = 'demo',
  Production = 'production'
}

export const getEnvironment = (): Environment => {
  const domain = process.env.NEXT_PUBLIC_APP_DOMAIN

  if (domain.indexOf('localhost') !== -1) return Environment.Local
  if (domain.indexOf('.staging') !== -1) return Environment.Staging
  if (domain.indexOf('.demo') !== -1) return Environment.Demo
  return Environment.Production
}

export const isLocalStagingOrDemo = (): boolean =>
  [Environment.Local, Environment.Staging, Environment.Demo].includes(
    getEnvironment()
  )

export const getSubdomainByEnv = (): string => {
  const environment = getEnvironment()

  switch (environment) {
    case Environment.Local:
    case Environment.Staging:
      return 'staging.'
    case Environment.Demo:
      return 'demo.'
    case Environment.Production:
    default:
      return ''
  }
}

export const isStaging = (): boolean =>
  process.env.NEXT_PUBLIC_APP_DOMAIN === 'http://localhost:2000' ||
  process.env.NEXT_PUBLIC_APP_DOMAIN === 'http://localhost:2001' ||
  process.env.NEXT_PUBLIC_APP_DOMAIN === 'https://app.staging.alternative.co' ||
  process.env.NEXT_PUBLIC_APP_DOMAIN === 'https://app.demo.alternative.co' ||
  process.env.NEXT_PUBLIC_APP_DOMAIN ===
    'https://app.staging.alternativepayments.io' ||
  process.env.NEXT_PUBLIC_APP_DOMAIN ===
    'https://app.demo.alternativepayments.io'

export const setEnv = (): Environment => {
  switch (process.env.NEXT_PUBLIC_APP_DOMAIN) {
    case 'https://app.alternative.co':
    case 'https://app.alternativepayments.io':
      return Environment.Production
    case 'https://app.staging.alternative.co':
    case 'https://app.demo.alternative.co':
    case 'https://app.staging.alternativepayments.io':
    case 'https://app.demo.alternativepayments.io':
      return Environment.Staging
    default:
      return Environment.Local
  }
}

export const getEnvironmentByDomain = (domain: string): Environment => {
  if (domain.indexOf('localhost') !== -1) return Environment.Local
  if (domain.indexOf('.staging') !== -1) return Environment.Staging
  if (domain.indexOf('.demo') !== -1) return Environment.Demo
  return Environment.Production
}

export const getPartnerAppDomain = (host: string): string => {
  const environment = getEnvironmentByDomain(host)

  switch (environment) {
    case Environment.Local:
      return 'http://localhost:2000'
    case Environment.Staging:
      return 'https://app.staging.alternativepayments.io'
    case Environment.Demo:
      return 'https://app.demo.alternativepayments.io'
    case Environment.Production:
      return 'https://app.alternativepayments.io'
    default:
      return 'https://app.alternativepayments.io'
  }
}

export const getAdminAppDomain = (host: string): string => {
  const environment = getEnvironmentByDomain(host)

  switch (environment) {
    case Environment.Local:
      return 'http://localhost:2002'
    case Environment.Staging:
      return 'https://admin.staging.alternativepayments.io'
    case Environment.Demo:
      return 'https://admin.demo.alternativepayments.io'
    case Environment.Production:
      return 'https://admin.alternativepayments.io'
    default:
      return 'https://admin.alternativepayments.io'
  }
}

export const paymentsPortalLocalUrl = 'http://localhost:2001'
